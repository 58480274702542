import { useState, useRef, useCallback } from "react";

import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { AuthConsumer } from "../../../contexts/AuthProvider";

const productsMap = {
  Pension: "Pensionserbjudande",
  Privat: "Privaterbjudande",
  Företag: "Företagserbjudande"
};

const useContactCard = props => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const auth = AuthConsumer();
  const { user, elevatedUser } = auth;
  const [state, setState] = useState({ status: "idle", message: "" });

  const { title, icon, text, image } = props;

  const sendMessage = useCallback(async () => {
    // If is being impersonated do nothing
    if (elevatedUser && elevatedUser?.LegalEntityId !== user.LegalEntityId) {
      return;
    }

    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    setState(prevState => ({ ...prevState, status: "sending" }));
    const name = user?.Name || `${user?.FirstName} ${user?.LastName}`;

    const messageData = {
      messageThreadId: null,
      toRecipients: [{ LegalEntityId: 0 }],
      subject: "En kund vill bli kontaktad",
      body: `${name} vill bli kontaktad gällande ${productsMap[title]}`,
      attachedFiles: [],
      customRecipients: []
    };

    try {
      const response = await axiosInstance.post("/message/send", messageData);

      if (response.status !== 204 && response.status !== 200) {
        return setState({
          status: "error",
          message: "Kunde inte skicka meddelandet. Försök igen senare."
        });
      }
      //success
      setState({
        status: "sent",
        message:
          "Tack för din förfrågan! Vi återkommer till dig så snart som möjligt."
      });
    } catch (err) {
      setState({
        status: "error",
        message: "Kunde inte skicka meddelandet. Försök igen senare."
      });
      console.log(err);
      errorHandler(err);
    }
  }, [
    elevatedUser,
    user.LegalEntityId,
    user?.Name,
    user?.FirstName,
    user?.LastName,
    initAxios,
    title,
    errorHandler
  ]);

  return {
    title,
    icon,
    text,
    image,
    state,
    setState,
    sendMessage
  };
};

export default useContactCard;
