import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";

const LinksButton = props => (
  <Button
    disableRipple
    disableFocusRipple
    endIcon={<ArrowForwardIosIcon />}
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      fontSize: "1rem",
      fontWeight: 400,
      color: "common.black",
      minHeight: 70,
      marginBottom: 1,
      justifyContent: "flex-start",
      backgroundColor: "common.white",
      border: 0,
      "&:hover": {
        border: 0
      },
      ...props.sx
    }}
  >
    <Box sx={{ display: "flex", minWidth: "90%" }}>{props.children}</Box>
  </Button>
);

export default LinksButton;

LinksButton.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
