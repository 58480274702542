import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import HomePaper from "../../theme/styled/HomePaper";
import DashboardPaper from "../../theme/styled/DashboardPaper";
import FairButton from "../../theme/styled/FairButton";

import { stripHtmlTags } from "../../helpers/functions";

const DynamicPaper = props =>
  props.dashboard ? (
    <DashboardPaper>{props.children}</DashboardPaper>
  ) : (
    <HomePaper>{props.children}</HomePaper>
  );

const RssFeed = props => {
  const { dashboard, data } = props;

  return (
    <DynamicPaper dashboard={dashboard}>
      {!dashboard ? (
        <Typography variant="h3" align="center" sx={{ mb: 2.5 }}>
          Senaste nytt från Fairbloggen
        </Typography>
      ) : (
        <Typography variant="h6" align="left">
          Aktuellt från oss
        </Typography>
      )}

      <List
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: 0,
          pb: 0
        }}
      >
        {data.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              maxWidth: 650,
              mb: 2,
              borderBottom: 1,
              borderColor: "#f1f3f4"
            }}
          >
            <Link
              href={item.article_url}
              rel="noopener noreferrer"
              target="_blank"
              sx={{
                width: "100%",
                p: 0,
                textAlign: "left"
              }}
            >
              {/* <ListItemAvatar
                sx={{
                  display: "inline-block",
                  width: { xs: "30%", xl: "35%" },
                  maxWidth: 120
                  // minWidth: { xs: 70, md: 100 }
                }}
              >
                <Avatar
                  variant="square"
                  alt="feed image"
                  src={item.imageUrl}
                  sx={{
                    width: "100%",
                    height: "auto"
                  }}
                />
              </ListItemAvatar> */}
              <ListItemText
                primary={
                  <Typography
                    variant="h7"
                    color="common.black"
                    dangerouslySetInnerHTML={{
                      __html: stripHtmlTags(item.title)
                    }}
                  />
                }
                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                secondary={
                  <Typography
                    variant="body2"
                    color="common.black"
                    dangerouslySetInnerHTML={{
                      __html: stripHtmlTags(item.description)
                    }}
                  />
                }
                sx={{
                  display: "inline-block",
                  p: { xs: 0.2, sm: 0.8 },
                  pl: { xs: 0.8, md: 1.5 },
                  m: 0
                }}
              />
            </Link>
          </Box>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <FairButton
            href="https://fairinvestments.se/blogg/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Till bloggen
          </FairButton>
        </Box>
      </List>
    </DynamicPaper>
  );
};

export default RssFeed;

RssFeed.propTypes = {
  dashboard: PropTypes.bool,
  data: PropTypes.array
};

DynamicPaper.propTypes = {
  dashboard: PropTypes.bool,
  children: PropTypes.node
};
