import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const BUGSNAG_SECRET = import.meta.env.VITE_BUGSNAG_SECRET;
const ENVIRONMENT = process.env.NODE_ENV;

// Make sure browser addblockers are disabled
Bugsnag.start({
  apiKey: BUGSNAG_SECRET,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes:
    ENVIRONMENT === "development"
      ? []
      : ["error", "log", "navigation", "request", "user"]
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
