// import logo from "./logo.svg";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper.jsx";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox.jsx";
import DashboardPaper from "../../theme/styled/DashboardPaper";

import AuditLogTable from "./AuditLogTable";

import useResponsive from "../../hooks/useResponsive";
import useAuditLog from "../AuditLogSection/useAuditLog.js";

const AuditLogSection = () => {
  const isMobile = useResponsive("only", "xs");

  const { state, handleLogClick } = useAuditLog();

  return !state.loaded ? (
    <Box
      sx={{
        height: "100%",
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Audit Logs
        </Typography>
        {/* {!isMobile && (
          <Typography variant="subtitle1">
            Här kan du se dina transaktioner
          </Typography>
        )} */}
      </SectionHeaderPaper>
      <SectionWrapperBox sx={{ flexFlow: "column", mx: 2.5, mt: 5 }}>
        <DashboardPaper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuditLogTable
              isMobile={isMobile}
              logs={state.logs}
              handleLogClick={handleLogClick}
            />
          </LocalizationProvider>
        </DashboardPaper>
      </SectionWrapperBox>
    </Box>
  );
};
export default AuditLogSection;
