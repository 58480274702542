import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import useErrorHandler from "./useErrorHandler";

const EXCLUDED_PATHS = ["/reset/", "/unauthorised"];

const useAuthProvider = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [elevatedUser, setElevatedUser] = useState(null);
  const [tempUser, setTempUser] = useState(null);
  const [tempElevatedUser, setTempElevatedUser] = useState(null);
  const [isPoaAgent, setIsPoaAgent] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // check if path should be excluded from auth useEffect
  const isExcludedPath = EXCLUDED_PATHS.some(path => {
    return pathname.includes(path);
  });

  const login = useCallback(
    (user, elevatedUser = false, from = false) => {
      setUser(user);
      if (elevatedUser) {
        setElevatedUser(elevatedUser);
      } else if (user.Role === "Backoffice" || user.Role === "Admin") {
        setElevatedUser(user);
      }

      if (user?.IsPoaAgent) {
        setIsLoaded(true);
        setIsPoaAgent(true);
        if (!elevatedUser) setElevatedUser(user);
        if (from && from !== "/") return navigate(from);

        return navigate("/poa");
      }

      setIsLoaded(true);
      return from && from !== "/" ? navigate(from) : navigate("/dashboard");
    },
    [navigate]
  );

  const logout = useCallback(
    // promptLogin = true needs fixing as it seems is being memoised (always true even if false is passed), because of useCallback wrapper??
    async (promptLogin = true) => {
      const { axiosInstance } = initAxios("auth");

      try {
        await axiosInstance.get("auth/logout");
        setUser(null);
        setIsPoaAgent(false);
        setElevatedUser(null);
        return navigate(`/${promptLogin ? "?login=1" : ""}`);
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [initAxios, navigate, errorHandler]
  );

  const getAuth = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("auth");
    axiosGlobalController.current = axiosController;

    try {
      const userResponse = await axiosInstance.get(`/auth/token`);
      return userResponse.data;
    } catch (err) {
      if (err?.response?.data?.msg === "Invalid token") {
        console.log(err, "err");
        return { user: null, elevatedUser: null, err };
      }
      errorHandler.serverError(err);
    }
  }, [initAxios, errorHandler]);

  const switchAuth = useCallback(
    async targetUser => {
      const { axiosInstance, axiosController } = initAxios("auth");
      axiosGlobalController.current = axiosController;

      try {
        if (targetUser) {
          await axiosInstance.post("auth/switch", {
            legalEntityId: targetUser.LegalEntityId
          });
        } else {
          await axiosInstance.get("auth/switch/elevated");
        }
        setIsLoaded(false); // set isLoaded to false to trigger useEffect to get new user
      } catch (err) {
        if (err?.response?.data?.msg === "Invalid token") {
          console.log(err, "err");
          logout(true);
        }
        errorHandler.serverError(err);
      }
    },
    [initAxios, errorHandler, logout]
  );

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        setTimeout(async () => {
          const auth = await getAuth();

          if (!user && auth.user) {
            window.location.reload();
          }

          if (!auth?.user || auth.err) {
            return logout(true);
          }

          if (auth.user?.LegalEntityId !== user?.LegalEntityId) {
            setTempUser(auth.user);
            setTempElevatedUser(elevatedUser);
            return setIsImpersonating(true);
          }
          setTempUser(null);
          setTempElevatedUser(null);
          setIsImpersonating(false);
        }, 500);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [elevatedUser, getAuth, logout, user, user?.LegalEntityId]);

  // check auth cookie/token useEffect
  useEffect(() => {
    if (!isLoaded) {
      const initAuth = async () => {
        const { user, elevatedUser, err } = await getAuth();

        setTempUser(null);
        setTempElevatedUser(null);
        setIsImpersonating(false);
        setIsLoaded(true);
        if (elevatedUser) {
          if (user?.IsPoaAgent || elevatedUser?.IsPoaAgent) setIsPoaAgent(true);
          setUser(user);
          return setElevatedUser(elevatedUser);
        } else if (user) {
          return setUser(user);
        } else if (err) {
          return logout(true);
        }
      };

      // do not request auth from certain paths i.e reset password
      !isExcludedPath ? initAuth() : setIsLoaded(true);
    }
  }, [isLoaded, logout, isExcludedPath, getAuth]);

  return {
    isLoaded,
    setIsLoaded,
    // setImpersating,
    user,
    elevatedUser,
    tempUser,
    tempElevatedUser,
    isPoaAgent,
    isImpersonating,
    switchAuth,
    setElevatedUser,
    setIsPoaAgent,
    setUser,
    login,
    logout
  };
};

export default useAuthProvider;
