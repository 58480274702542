import { useState, useRef, useEffect } from "react";

import useAxios from "../hooks/useAxios";
import useErrorHandler from "../hooks/useErrorHandler";
import { AuthConsumer } from "../contexts/AuthProvider";

const useTimeOutProvider = () => {
  const { initAxios } = useAxios();
  const errorHandler = useErrorHandler();
  const { user, elevatedUser, logout } = AuthConsumer();

  const TIMEOUT_MS =
    elevatedUser && !elevatedUser.IsPoaAgent
      ? import.meta.env.VITE_BACKOFFICE_TIMEOUT_MS
      : import.meta.env.VITE_TIMEOUT_MS;

  const TIMEOUT_NOTICE_MS = import.meta.env.VITE_TIMEOUT_NOTICE_MS;
  const TIME_ALMOST_OUT_MS = TIMEOUT_MS - TIMEOUT_NOTICE_MS;
  const NOTICE_COUNTDOWN_SECONDS = TIMEOUT_NOTICE_MS / 1000 - 1;

  let timer;
  const timeOut = useRef(null);
  const timeAlmostOut = useRef(null);
  const [countdown, setCountdown] = useState(NOTICE_COUNTDOWN_SECONDS);
  const [timeOutModal, setTimeOutModal] = useState({
    expired: false,
    notice: false
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCount = () => {
    timer =
      !timer &&
      setInterval(() => {
        setCountdown(prevCount => prevCount - 1);
      }, 1000);
  };

  const handleClose = () => {
    setCountdown(NOTICE_COUNTDOWN_SECONDS);
    setTimeOutModal({ notice: false, expired: false });
  };

  const handleTimeIsAlmostOut = () => {
    setTimeOutModal(prevState => ({
      ...prevState,
      notice: true
    }));
  };

  const handleTimeIsOut = () => {
    setTimeOutModal({ notice: false, expired: true });
    logout(false);
  };

  // softResetTimeOut to use with axios response interceptors. No state changes to avoid re-renders on every request
  // this is called after an axios "private" response so cookies/token would already've been reset in the backend
  const softResetTimeOut = () => {
    clearTimeout(timeOut.current);
    clearTimeout(timeAlmostOut.current);
    timeOut.current = setTimeout(handleTimeIsOut, TIMEOUT_MS);
    timeAlmostOut.current = setTimeout(
      handleTimeIsAlmostOut,
      TIME_ALMOST_OUT_MS
    );
  };

  const resetTimeOut = async () => {
    softResetTimeOut();
    setTimeOutModal({
      expired: false,
      notice: false
    });
    setCountdown(NOTICE_COUNTDOWN_SECONDS);

    const { axiosInstance } = initAxios("auth");
    try {
      await axiosInstance.get("auth/token/refresh");
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  useEffect(() => {
    if (timeOutModal.notice) updateCount();

    return () => clearInterval(timer);
  }, [timeOutModal.notice, timer, updateCount]);

  // timeout useEffect
  useEffect(() => {
    if (user) {
      timeOut.current = setTimeout(handleTimeIsOut, TIMEOUT_MS);
      timeAlmostOut.current = setTimeout(
        handleTimeIsAlmostOut,
        TIME_ALMOST_OUT_MS
      );
    } else {
      clearTimeout(timeOut.current);
      clearTimeout(timeAlmostOut.current);
    }

    return () => {
      clearTimeout(timeOut.current);
      clearTimeout(timeAlmostOut.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, logout]);

  return {
    timeOutModal,
    countdown,
    setCountdown,
    handleClose,
    resetTimeOut,
    softResetTimeOut
  };
};

export default useTimeOutProvider;
