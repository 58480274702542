import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import FairModalBox from "../../../theme/styled/FairModalBox";

const NotificationModal = props => {
  const { title, status, p1, p2, p3, setState } = props;

  const toggleModal = () => {
    setState(prevState => ({ ...prevState, status: "idle" }));
  };

  return (
    <Modal open={true}>
      <FairModalBox sx={{ width: 300, height: 300 }}>
        <Box
          sx={{
            height: 50,
            px: 4,
            pb: 5
          }}
        >
          <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
            {title}
          </Typography>
        </Box>
        {status === "sending" ? (
          <Box
            sx={{
              height: 135,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <CircularProgress />
            <br />
            <Typography color="black">Var god vänta... </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              height: 135,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <Typography
              variant="body2"
              align="center"
              color={status === "error" ? "error.main" : "black.common"}
              sx={{ mb: p2 ? 1 : 0 }}
            >
              {p1}
            </Typography>
            {p2 && (
              <Typography
                variant="body2"
                align="center"
                color={status === "error" ? "error.main" : "black.common"}
                sx={{ mb: p3 ? 1 : 0 }}
              >
                {p2}
              </Typography>
            )}
            {p3 && (
              <Typography
                variant="body2"
                align="center"
                color={status === "error" ? "error.main" : "black.common"}
              >
                {p3}
              </Typography>
            )}
          </Box>
        )}
        <Box
          sx={{
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexFlow: "column",
            px: 4
          }}
        >
          <div>
            <Button
              fullWidth
              onClick={toggleModal}
              sx={{
                color: "primary.lighter",
                my: 1
              }}
            >
              Stäng
            </Button>
          </div>
        </Box>
      </FairModalBox>
    </Modal>
  );
};

export default NotificationModal;

NotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  p1: PropTypes.string.isRequired,
  p2: PropTypes.string,
  p3: PropTypes.string,
  setState: PropTypes.func.isRequired
};
